















import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import MyAdminShowType from "./index"
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store";
import {DelFriends} from "@/views/Friend/FriendSuccess/Server";
import {Toast} from "vant";


@Component({name: "MyAdminShow"})
export default class MyAdminShow extends Vue implements MyAdminShowType{
    show = false

    mounted(){
        this.show = this.getMyAdminShow
    }

    handleClose(): void {
        FriendSuccessStore.setMyAdminShow( false )
    }

    handleRemoveClick(): void {
        let { id } = this.$route.query
        DelFriends(id as string).then(res=>{
            let time = setTimeout(()=>{
                Toast({
                    message:"删除成功"
                })
                this.$router.go(-1)
                clearTimeout( time )
            },600)
        })
    }
    @Watch("show")
    changeShow(newVal:boolean){
        FriendSuccessStore.setMyAdminShow( newVal )
    }

    get getMyAdminShow(){
        return FriendSuccessStore.getMyAdminShow
    }
    @Watch("getMyAdminShow")
    changeMyAdminShow(newVal:boolean){
        this.show = newVal
    }
}
