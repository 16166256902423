
























































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import IndexType from "@/views/Friend/FriendSuccess/components/Content/indexType";
import { ImagePreview } from "vant"
import { computedTime } from "@/util/computedTime"
import { dataType } from "../../FriendSuccess"
import {getGive} from "@/views/Friend/FriendSuccess/Server";
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store";

@Component({name: "Content"})
export default class Content extends Vue implements IndexType{
    AddressIcon = require("../../Img/FirendAddress.png");
    CartIcon = require("../../../Img/FirendChat.png");
    GiveIcon = require("../../../Img/FirendGive.png");
    NotGiveIcon = require("../../../Img/FirendNotGive.png");
    ShareSIcon = require("../../Img/FirendShare.png");
    active = false;


    handleComment(): void {
        FriendSuccessStore.setCommentShow(true)
        let data = FriendSuccessStore.getCommentData
        data.momentsId = this.getData.id as number
        FriendSuccessStore.setCommentData(data)
    }

    handleGive(): void {
        this.getData && getGive(this.getData.id as number).then(res=>{
            this.active = !this.active
            this.$emit("reftch")
        })
    }

    handlePerView(index: number): void {
        ImagePreview({
            images:this.ImageList,
            startPosition:index
        })
    }

    handleShare(): void {
        //
    }

    handleToUser(): void {
        this.$router.push({
            path:"/addFriend",
            query:{
                id:this.getData.userId as string
            }
        })
    }

    init(): void {
        if ( Number(this.getData.isLike) ){
            this.active = true
        }else{
            this.active = false
        }
    }

    get getPhoto(){
        return this.getData && this.getData.headImg || require("@/assets/icon/Basics/default.png");
    }

    get ImageList(){
        if ( !(this.getData && this.getData.image ) ) return [];
        if ( String(this.getData.image).length ){
            let Arr = String(this.getData.image).split(",")
            return Arr
        }else{
            return []
        }
    }

    get getTime(){
        // eslint-disable-next-line
        // @ts-ignore
        return this.getData &&  computedTime(this.getData.createTime as string)
    }

    @Prop(Object)
    data!:dataType
    get getData(){
        return this.data
    }
    @Watch("getData")
    changeData(){
        this.init()
    }
}
