

















































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import IndexType from "@/views/Friend/FriendSuccess/components/CommentItem/indexType";
import { computedTime } from "@/util/computedTime"
import Storage from "@/util/Storage"
import {commentGive, DelComment} from "@/views/Friend/FriendSuccess/Server";
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store";

@Component({name: "CommentItem",components:{}})
export default class CommentItem extends Vue implements IndexType{
    CartIcon = require("../../../Img/FirendChat.png");
    GiveIcon = require("../../../Img/FirendGive.png");
    NotGiveIcon = require("../../../Img/FirendNotGive.png");
    removeIcon = require("../../../Img/Del.png");
    active = false

    handleToUser() {
        this.$router.push({
            path: "/addFriend",
            query:{
                id:this.getData.userId
            }
        })
    }

    handleToCommentList() { //  跳转到二级评论列表
        this.$router.push({
            path: "/friendComment",
            query:{
                id:this.getData.id,
                friendId:this.getData.momentsId,
                data:JSON.stringify(this.getData),
            }
        })
    }

    handleRemoveComment(){
        this.getData && DelComment(this.getData.id).then(res=>{
            this.$emit("delComment",{ index:this.getIndex })
        })
    }

    handleComment(){
        let data = FriendSuccessStore.getCommentData
        data.momentsId = this.getData.momentsId || this.$route.query.id
        data.toId = this.getData.id
        data.secondComment = true
        FriendSuccessStore.setCommentData( data )
        FriendSuccessStore.setCommentShow(true)
    }

    handleGive(){
        this.getData && commentGive(this.getData.id).then(res=>{
            this.active = !this.active
        })
    }

    init(){
        if ( this.getData && Number(this.getData.isLike) ){
            this.active = true
        }else{
            this.active = false
        }
    }

    get getTime(){
        return  computedTime( this.getData.createTime )
    }

    get getPhoto(){
        return this.getData && this.getData.headImg || require("@/assets/icon/Basics/default.png")
    }

    get getIsComment(){
        if ( this.getData && Boolean(this.getData.luckUser) ) {
            return true
        }else{
            return false
        }
    }

    get getRemoveShow(){
        let userId = Storage.GetData_ && Storage.GetData_("userId")
        if ( this.getData && Number(this.getData.userId) === Number(userId) ){
            return true
        }else{
            return false
        }
    }

    @Prop(Object)
    data!:any
    get getData(){
        return this.data
    }
    @Watch("getData")
    changeData(){
        this.init()
    }

    @Prop(Number)
    index!:number
    get getIndex(){
        return this.index
    }
}
