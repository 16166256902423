




































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import IndexType from "@/views/Friend/FriendSuccess/components/Comment/indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import CommentItem from "@/views/Friend/FriendSuccess/components/CommentItem/index.vue";
import { dataType } from "../../FriendSuccess"
import {commentList} from "@/views/Friend/FriendSuccess/Server";
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store/index";
import { commentType } from "../../FriendSuccess"
import { testComment } from "../../Model/index"

@Component({name: "Comment",components:{ PullDownUpList,ListBottom,CommentItem }})
export default class Comment extends Vue implements IndexType{
    PullDown = false;
    UpDownBool = false;
    StopUp = false
    navIndex = 1
    List:commentType[] = []

    handelFilterCommentList(e: { index:number }): void {
        let { index } = e
        let data = FriendSuccessStore.getList
        data.splice(index,1)
        FriendSuccessStore.setListSuc( data )
    }

    handlePullDown(): void {
        //
    }

    handleUpDown(): void {
        if ( this.StopUp )return;
        let data = FriendSuccessStore.getUpDataSuc
        data.pageNo += 1
        FriendSuccessStore.setUpDataSuc( data )
        this.handleUpData()
    }

    handleUpData(bool=false): void {
        commentList().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < FriendSuccessStore.getUpDataSuc.pageSize )this.StopUp = true;
            if ( bool )
                FriendSuccessStore.setListSuc( res );
            else
                FriendSuccessStore.setListSuc( FriendSuccessStore.getList.concat(res) );
        })
    }

    @Prop(Object)
    data!:dataType
    get getData(){ return this.data }
    @Watch("getData")
    changeData(newVal:dataType){
        if ( JSON.stringify(newVal) == "{}" )return;
        let data = FriendSuccessStore.getUpDataSuc
        FriendSuccessStore.setUpDataSuc( { ...data,momentsId:newVal.id as number } )
        this.handleUpData(true)
    }

    get getList(){
        return FriendSuccessStore.getList
    }
    @Watch("getList",{ deep:true,immediate:true })
    changeList(newVal:commentType[]){
        this.List = newVal
    }
}
