


























import {Vue, Component} from "vue-property-decorator";
import IndexType,{ dataType } from "@/views/Friend/FriendSuccess/FriendSuccess";
import FriendContent from "@/views/Friend/FriendSuccess/components/Content/index.vue"
import FriendComment from "@/views/Friend/FriendSuccess/components/Comment/index.vue"
import CommentShow from "@/views/Friend/components/CommentShow/index.vue";
import MyAdminShow from "@/views/Friend/FriendSuccess/components/AdminShow/index.vue";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import {getFriendSuccess} from "@/views/Friend/FriendSuccess/Server";
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store";
import ZoomPage from "@/impView/PageSafety";
import { testData } from "./Model/index"

@Component({name: "FriendSuccess",components:{ FriendContent,FriendComment,CommentShow,MyAdminShow,HeadTop }})
export default class FriendSuccess extends ZoomPage implements IndexType{
    orBoolIcon = require("../Img/ellipsis.png");
    data:dataType = {};
    loadingShow = true

    mounted(){
        this.handleUpData()
    }

    handleBack(){
        // FriendSuccessStore.setMyAdminShow(false)
        // FriendSuccessStore.setCommentShow(false)
        this.$router.go(-1)
    }

    handleUpData(){
        let { id = 0 } = this.$route.query
        getFriendSuccess(id as number).then(res=>{
            this.data = res
            this.loadingShow = false
        })
    }

    handleOrClick(){
        FriendSuccessStore.setMyAdminShow(true)
    }

    get getMyAdmin(){
        let { id,...props } = this.$route.query
        if ( "state" in  props ){
            if ( props["state"] == "myAdmin" ){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }
}
